<template>
  <base-section
    id="purchase-license"
    class="grey lighten-4"
  >
    <v-container class="fill-height px-4 py-12">
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <v-responsive
            class="d-flex align-center"
          >
            <base-subheading :title="$t('purchaseTitle')" />

            <base-body>
              {{$t('purchaseContent1')}}
              <br>
              <br>
              {{$t('welcomeContent')}}
            </base-body>

            <base-subheading :title="$t('purchaseConcurrency')" />

            <base-body>
              {{$t('purchaseContent3')}}
              <br>
              <br>
              {{$t('purchaseContent4')}}
            </base-body>
            <base-subheading :title="$t('priceTitle')" />

            <base-body>
              {{$t('priceContent1')}}
              <br>
              <br>
              {{$t('priceContent2')}}
            </base-body>

          </v-responsive>
        </v-col>

        <v-col
          cols="12"
          md="8"
        >
          <base-info-card
            :title="$t('licenseLicense')"
            space="4"
            color="primary"
          />
          <v-card
            elevation="10"
            class="mb-12"
          >
            <base-img
              max-width="100%"
              src="screenshots/olapdb_license.png"
            />
          </v-card>
        </v-col>

        <!--免费试用-->
        <!--<v-col-->
          <!--cols="12"-->
          <!--md="8"-->
        <!--&gt;-->
          <!--<base-subheading :title="$t('trialTitle')" />-->
        <!--</v-col>-->

        <!--<v-col-->
          <!--cols="12"-->
          <!--md="8"-->
        <!--&gt;-->
          <!--<base-license-form :title="$t('licenseTitle')" />-->
        <!--</v-col>-->

        <!--正式购买-->
        <v-col
          cols="12"
          md="8"
        >
          <br>
          <base-subheading :title="$t('purchaseOfficialLicense')" />
        </v-col>

        <v-col
          cols="12"
          md="8"
        >
          <base-purchase-license-form :title="$t('purchaseFillOlapdbInfo')" />
        </v-col>

      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPurchaseLicense',

    data: () => ({
      downloads: [
      ],
    }),
  }
</script>
